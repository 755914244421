import React from "react";

// Public Imports
const ForgetPassword = React.lazy(() => import("../containers/AuthPages/ForgetPassword"));
const ResetPassword = React.lazy(() => import("../containers/AuthPages/ResetPassword"));
const SetPassword = React.lazy(() => import("../containers/AuthPages/SetPassword/SetPassword"));
const Login = React.lazy(() => import("../containers/AuthPages/Login"));
const EmailSent = React.lazy(() => import("../containers/AuthPages/EmailSent/EmailSent"));

// Protected Imports
const Ads = React.lazy(() => import("../containers/DashboardPages/Ads/Ads"));
const Activities = React.lazy(() => import("../containers/DashboardPages/Activities/Activities"));
const Notifications = React.lazy(() => import("../containers/DashboardPages/Notifications/Notifications"));
const Dashboard = React.lazy(() => import("../containers/DashboardPages/Dashboard/Dashboard"));
const QrCodes = React.lazy(() => import("../containers/DashboardPages/QrCodes/QrCodes"));
const ProfileUpdate = React.lazy(() => import("../containers/Profile/profileUpdate"));
const SharingImage = React.lazy(() => import("../containers/DashboardPages/SharingImage/SharingImage"));

export const UNAUTHENTICATED_ROUTES = {
    LOGIN: "/login",
    RESET_PASSWORD: "/reset-password/:resetToken/:email/:token",
    FORGET_PASSWORD: "/forget-password",
    SET_PASSWORD: "/set-password/:token",
    EVENT_SUCCESS: "/event-submitted/:email"
};

export const AUTHENTICATED_ROUTES = {
    DASHBOARD: "/dashboard",
    ACTIVITIES: "/activities",
    ADS: "/ads",
    NOTIFICATIONS: "/notifications",
    SHARING_IMAGE: "/sharing-image",
    QR_CODES: "/qr-codes",
    PROFILE_UPDATE: "/profile-update"
};

export const PUBLIC_ROUTES = [
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.LOGIN,
        component: Login,
        title: "Login"
    },
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.FORGET_PASSWORD,
        component: ForgetPassword,
        title: "Forget Password"
    },
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.RESET_PASSWORD,
        component: ResetPassword,
        title: "Reset Password"
    },
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.EVENT_SUCCESS,
        component: EmailSent,
        title: "Email Sent Successfully"
    },
    {
        exact: true,
        path: UNAUTHENTICATED_ROUTES.SET_PASSWORD,
        component: SetPassword,
        title: "Set Password"
    }
];

export const ALL_APP_ROUTES = [
    {
        exact: true,
        path: AUTHENTICATED_ROUTES.DASHBOARD,
        component: Dashboard,
        title: "Dashboard"
    },
    {
        exact: true,
        path: AUTHENTICATED_ROUTES.ACTIVITIES,
        component: Activities,
        title: "Ads"
    },
    {
        exact: true,
        path: AUTHENTICATED_ROUTES.ADS,
        component: Ads,
        title: "Ads"
    },
    {
        exact: true,
        path: AUTHENTICATED_ROUTES.NOTIFICATIONS,
        component: Notifications,
        title: "Notifications"
    },
    {
        exact: true,
        path: AUTHENTICATED_ROUTES.SHARING_IMAGE,
        component: SharingImage,
        title: "Sharing Image"
    },
    {
        exact: true,
        path: AUTHENTICATED_ROUTES.QR_CODES,
        component: QrCodes,
        title: "Scanner QR Codes"
    },
    {
        exact: true,
        path: AUTHENTICATED_ROUTES.PROFILE_UPDATE,
        component: ProfileUpdate,
        title: "Profile"
    }
];
export const AD_STATUS = {
    ACTIVE: true,
    INACTIVE: false
};

export const SAMPLE_OTP = "0000";
