import { Col, Menu, Row } from "antd";
import React, { useCallback } from "react";
import { useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { MAIN_SIDE_BAR_OPTIONS } from "../../constants/Roles";
import { NAV_STYLE_MINI_SIDEBAR, NAV_STYLE_NO_HEADER_MINI_SIDEBAR } from "../../constants/ThemeSetting";
import "./index.less";
import SidebarLogo from "./SidebarLogo";

const SidebarContent = () => {
    let sideBarOptions = MAIN_SIDE_BAR_OPTIONS;
    const { pathname } = useLocation();
    const { SubMenu, Item } = Menu;

    const { navStyle } = useSelector(({ settings }) => settings);
    const isNavClosed = navStyle === NAV_STYLE_MINI_SIDEBAR || navStyle === NAV_STYLE_NO_HEADER_MINI_SIDEBAR;

    const renderSideBarOptions = useCallback(
        (props) => {
            if (!props.sideBarOptions) {
                return;
            }
            return (
                <>
                    <Item className="child-menu">
                        <div className="poppins-regular gx-text-gray">
                            <Row className="gx-m-0">
                                <Col>
                                    <span>Menu</span>
                                </Col>
                            </Row>
                        </div>
                    </Item>
                    {sideBarOptions.map((singleSidebarOption) => {
                        return (
                            <Item
                                key={singleSidebarOption.selectedOptionKey}
                                title={singleSidebarOption.text}
                                className={isNavClosed ? "" : "sidebar-menu-container"}
                            >
                                <Link to={singleSidebarOption.linkTo} className="poppins-regular">
                                    <span className="poppins-regular gx-text-white">
                                        <img src={singleSidebarOption.icon} style={{ marginRight: "12px" }} />
                                        {!isNavClosed ? <span>{singleSidebarOption.text}</span> : null}
                                    </span>
                                </Link>
                            </Item>
                        );
                    })}

                    <div className="side-menu-bottom ">
                        <img src={require("../../assets/logo/sidebar-bottom-icon.svg")} alt="" />
                    </div>
                </>
            );
        },
        [isNavClosed]
    );

    let selectedKeys = pathname.substr(1);
    const defaultOpenKeys = selectedKeys.split("/")[0];

    console.log(selectedKeys, defaultOpenKeys);

    return (
        <>
            <SidebarLogo />
            <div className="gx-sidebar-content sidebar-scroll custom-sidebar-scroll">
                <Menu
                    defaultOpenKeys={[defaultOpenKeys]}
                    selectedKeys={[selectedKeys]}
                    theme={"light"}
                    mode="inline"
                    className="custom-sidebar-scroll side-menu"
                >
                    {renderSideBarOptions({ sideBarOptions: sideBarOptions })}
                </Menu>
            </div>
        </>
    );
};

SidebarContent.propTypes = {};
export default SidebarContent;
