import { STORAGE_KEY } from "../constants/Storage";
import LocalStorageService from "../util/local-storage.service";

const AuthService = {
    isTokenExist,
    getToken,
    getUserInfo,
    getRole,
    isAdminTokenExist
};

function getRole() {
    let userInfo = LocalStorageService.get(STORAGE_KEY.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user?.role;
    }
    return null;
}

function isTokenExist() {
    let token = sessionStorage.getItem(STORAGE_KEY.TOKEN);
    if (token) {
        return true;
    }
    return false;
}
function isAdminTokenExist() {
    let token = LocalStorageService.get(STORAGE_KEY.TOKEN);
    if (token) {
        return true;
    }
    return false;
}

function getToken() {
    let token = LocalStorageService.get(STORAGE_KEY.TOKEN);
    if (token) {
        return token;
    }
    return null;
}

function getUserInfo() {
    let userInfo = LocalStorageService.get(STORAGE_KEY.USER_INFO);
    if (userInfo) {
        userInfo = JSON.parse(userInfo);
        return userInfo?.user;
    }
    return null;
}

export default AuthService;
