import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { useThemeSwitcher } from "react-css-theme-switcher";

import { onNavStyleChange, toggleCollapsedSideNav } from "../../appRedux/slices/Settings";
import Icon from "../../assets/logo/side-drawer-icon.png";
import "./index.less";

import {
    NAV_STYLE_DRAWER,
    NAV_STYLE_FIXED,
    NAV_STYLE_MINI_SIDEBAR,
    NAV_STYLE_NO_HEADER_MINI_SIDEBAR,
    TAB_SIZE,
    THEME_TYPE_LITE
} from "../../constants/ThemeSetting";
import MainLogo from "../../components/Logo/MainLogo";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const SidebarLogo = () => {
    const { currentTheme } = useThemeSwitcher();
    const dispatch = useDispatch();
    const { width, themeType, navCollapsed } = useSelector(({ settings }) => settings);
    let navStyle = useSelector(({ settings }) => settings.navStyle);
    if (width < TAB_SIZE && navStyle === NAV_STYLE_FIXED) {
        navStyle = NAV_STYLE_DRAWER;
    }
    return (
        <div
            style={{ borderBottom: "2px solid white" }}
            className="gx-layout-sider-header gx-d-flex gx-align-items-center gx-justify-content-between"
        >
            {width < TAB_SIZE ? (
                <Link to="/" className="gx-site-logo side-logo-container ">
                    <img src={Icon} alt="app-logo" />
                </Link>
            ) : null}
            {navStyle === NAV_STYLE_FIXED && (
                <Link to="/" className="gx-site-logo side-logo-container ">
                    <img src={Icon} alt="app-logo" />
                </Link>
            )}
        </div>
    );
};

export default SidebarLogo;
