export const STORAGE_KEY = {
    USER_INFO: "USER_INFO",
    ADMIN_INFO: "ADMIN_INFO",
    TOKEN: "TOKEN",
    MAJOR_CITIES: "MAJOR_CITIES",
    GAMES_VS_USERS: "GAMES_VS_USERS",
    STATISTICS_ROUND: "STATISTICS_ROUND",
    GAMES_COMPLETED: "GAMES_COMPLETED",
    DETAIL_STATS: "DETAIL_STATS",
    COUNTRY_CITY: "COUNTRY_CITY",
    COUNTRIES: "COUNTRIES",
    CLICKS_RATE: "CLICKS_RATE",
    CUSTOM_ADVERTS: "CUSTOM_ADVERTS",
    GOOGLE_ADVERTS: "GOOGLE_ADVERTS",
    NOTIFICATIONS_ENABLED: "NOTIFICATIONS_ENABLED",
    QR_CODE_CARDS: "QR_CODE_CARDS",
    ADS: "ADS",
    ACTIVTY_LIST: "ACTIVITY_LIST",
    ACTIVTY_HISTORY_LIST: "ACTIVTY_HISTORY_LIST"
};
