import { ConnectedRouter } from "connected-react-router";
import { ReactQueryDevtools } from "react-query/devtools";
import React from "react";
import { ThemeSwitcherProvider } from "react-css-theme-switcher";
import { QueryClient, QueryClientProvider } from "react-query";
import { Provider } from "react-redux";

import configureStore, { history } from "./appRedux/store";
import withClearCache from "./ClearCache";
import FullPageLoader from "./components/FullPageLoader";
import { THEME } from "./constants/constant";
import MainApp from "./containers/App/MainApp";
import UnAuthenticatedApp from "./routes/unauthenticated-app-routes";
import AuthService from "./util/auth.service";
import "assets/vendors/style";

export const store = configureStore();

const themes = {
    light: `${process.env.PUBLIC_URL}/light-theme.css`
};
const isTokenExist = AuthService.isTokenExist();

const currentTheme = THEME.LITE;

export const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            staleTime: 1000 * 60,
            getNextPageParam: (lastPage) => {
                if (lastPage?.count / 10 > +lastPage.page) {
                    return Number(lastPage?.page);
                }
            }
        }
    }
});
const RegisterRoutes = () => {
    if (isTokenExist) {
        return <MainApp />;
    } else {
        return <UnAuthenticatedApp />;
    }
    // return <MainApp />;
};

const App = () => (
    <React.Suspense fallback={<FullPageLoader />}>
        <React.StrictMode>
            <ThemeSwitcherProvider
                themeMap={themes}
                defaultTheme={currentTheme}
                insertionPoint="styles-insertion-point"
            >
                <Provider store={store}>
                    <QueryClientProvider client={queryClient}>
                        <ConnectedRouter history={history}>{RegisterRoutes()}</ConnectedRouter>
                        <ReactQueryDevtools position="bottom-right" />
                    </QueryClientProvider>
                </Provider>
            </ThemeSwitcherProvider>
        </React.StrictMode>
    </React.Suspense>
);

const ClearCacheComponent = withClearCache(App);

const NextApp = () => {
    return <ClearCacheComponent />;
};

export default NextApp;
