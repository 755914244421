import AWS from "aws-sdk";
import moment from "moment";
import { MapCardDetails } from "../constants/constant";
import notificationService from "./notification.service";

const convertIntoUnix = (date) => {
    return moment(date).local().unix();
};

const convertFromUnix = (date, format = "MM/DD/YYYY", fromCurrentTime) => {
    if (fromCurrentTime) {
        return moment.unix(date).fromNow();
    }
    return moment.unix(date).format(format);
};

const convertDateTime = (date, format, fromCurrentTime) => {
    if (fromCurrentTime && format) {
        return moment(date, format).fromNow();
    }
    if (fromCurrentTime) {
        return moment(date).fromNow();
    }
    return moment(date).format(format);
};

const getLocal = (format) => {
    return moment().local().format(format);
};

const getFromToday = (format, number, identifier) => {
    return moment().local().subtract(number, identifier).format(format);
};

function isAfter(date1, date2) {
    return moment(date1).isAfter(date2);
}

const normalizeOptionsForSelector = (data) => {
    if (data) {
        const normalizedData = data.map((item) => {
            return {
                label: item,
                value: item
            };
        });
        return normalizedData;
    }
    return [];
};

const onImageDownload = async (item) => {
    // QR Code Download
    const svg = document.getElementById("QRCode");
    const svgData = new XMLSerializer().serializeToString(svg);
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");
    const img = new Image();
    img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
        const pngFile = canvas.toDataURL("image/png");
        const downloadLink = document.createElement("a");
        downloadLink.download = `QRCode-${item.suitType}-${item.value}`;
        downloadLink.href = `${pngFile}`;
        downloadLink.click();
    };
    img.src = `data:image/svg+xml;base64,${btoa(svgData)}`;

    // Card Download
    const image = await fetch(MapCardDetails[item.suitType]?.[item.value]?.image);
    const imageBlog = await image.blob();
    const imageURL = URL.createObjectURL(imageBlog);
    const link = document.createElement("a");
    link.download = `DeckCard-${item.suitType}-${item.value}`;
    link.href = imageURL;
    link.click();
};
const uploadOnS3 = async (stream, credentials) => {
    if (!AWS) {
        return;
    }

    AWS.config.update({ region: credentials.region });

    let s3 = new AWS.S3({
        credentials: new AWS.Credentials({
            accessKeyId: credentials.accessKeyId,
            secretAccessKey: credentials.secretAccessKey,
            sessionToken: credentials.sessionToken,
            signatureVersion: "v4",
            region: credentials.region
        })
    });

    let uploadedItem = await s3
        .upload(
            {
                Bucket: credentials.bucket,
                Key: credentials.location,
                ACL: "public-read",
                Body: stream
            },
            function () {
                notificationService.error("Something Went Wrong");
            }
        )
        .promise();
    return uploadedItem;
};

export {
    convertIntoUnix,
    convertFromUnix,
    convertDateTime,
    getLocal,
    isAfter,
    getFromToday,
    normalizeOptionsForSelector,
    onImageDownload,
    uploadOnS3
};
