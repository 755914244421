import { Spin } from "antd";
import React from "react";

function FullPageLoader({ tip, size, className, indicator }) {
    return (
        <React.Fragment>
            <div className="loader loading gx-d-flex gx-align-items-center gx-justify-content-center">
                {/* <Spin indicator={indicator ? indicator : undefined} size={"large"} tip={tip} spinning /> */}
                <img src={require("../../assets/images/loading.png")} alt="" />
            </div>
        </React.Fragment>
    );
}

export default FullPageLoader;
