import { Images } from "../assets/side-menu-icons/sideBar-images/Images";
import { AUTHENTICATED_ROUTES } from "../routes/constant";

export const ROLE_DEFAULT_ROUTE = {
    Admin: AUTHENTICATED_ROUTES.DASHBOARD,
    User: AUTHENTICATED_ROUTES.DASHBOARD
};

export const ROLES = {
    GlobalAdmin: "GlobalAdmin",
    User: "User",
    RegionalAdmin: "RegionalAdmin"
};

export const MAIN_SIDE_BAR_OPTIONS = [
    {
        text: "Dashboard",
        linkTo: AUTHENTICATED_ROUTES.DASHBOARD,
        selectedOptionKey: "dashboard",
        icon: Images.Dashboard
    },
    {
        text: "Ads",
        linkTo: AUTHENTICATED_ROUTES.ADS,
        selectedOptionKey: "ads",
        icon: Images.Ad
    },
    {
        text: "Notifications",
        linkTo: AUTHENTICATED_ROUTES.NOTIFICATIONS,
        selectedOptionKey: "notifications",
        icon: Images.Notifications
    },
    {
        text: "Sharing Image",
        linkTo: AUTHENTICATED_ROUTES.SHARING_IMAGE,
        selectedOptionKey: "sharing-image",
        icon: Images.SharingImage
    },
    {
        text: "Scanner QR Codes",
        linkTo: AUTHENTICATED_ROUTES.QR_CODES,
        selectedOptionKey: "qr-codes",
        icon: Images.QRCode
    }
];
