import { AUTHENTICATED_ROUTES } from "../routes/constant";
import { STORAGE_CONST } from "../util/constants/constant";

export const PAGINATION_CONSTANT = {
    PAGE_SIZE: 10,
    PAGE_NUMBER: 1
};

export const tableSort = {
    ASCENDING: "ascend",
    DESCENDING: "descend"
};

export const DESIGNATION_PAGINATION_CONSTANT = {
    PAGE_SIZE: 5,
    PAGE_NUMBER: 1
};

export const pageProperties = {
    WIDTH: 1024,
    OVERFLOW_Y: 250
};

export const supportedFileFormat = ["image/jpg", "image/jpeg", "image/png", "image/jfif"];

export const TOPIC_LOOKUP_TYPE = {
    ALL_NEIGHORHOOD: "2",
    CHAT_TOPICS: "1"
};

export const API_STORAGE_KEY = {
    VIEW_ACTIVITY: "VIEW_ACTIVITY",
    GENERAL_TAG_LOOKUP: "GENERAL_TAG_LOOKUP",
    ACCOMMODATION_LOOKUP: "ACCOMMODATION_LOOKUP",
    TRANSPORTATION_LOOKUP: "TRANSPORTATION_LOOKUP",
    NEIGHBORHOOD_LOOKUP: "NEIGHBORHOOD_LOOKUP",
    GENERAL_TAG_KEY: "GENERAL_TAG",
    ACCOMMODATION_KEY: "ACCOMMODATION",
    TRANSPORTATION_KEY: "TRANSPORTATION",
    FREEBIE_CATEGORY: "FREEBIE_CATEGORY",
    REPORTED_FREEBIES: "REPORTED_FREEBIES",
    USER_DETAILS: "USER_DETAILS",
    CATEGORY_LIST: "CATEGORY_LIST",
    ACCESS_LIST: "ACCESS_LIST",
    EXPLORE_LIST: "EXPLORE_LIST",
    ACCESS_LOOKUP: "ACCESS_LOOKUP",
    EXPLORE_LOOKUP: "EXPLORE_LOOKUP",
    CHAT_POST: "CHAT_POST",
    CHAT_COMMENT: "CHAT_COMMENT",
    REPLIES_DATA: "REPLIES_DATA",
    SUB_REPLIES_DATA: "SUB_REPLIES_DATA",
    PROFILE_INFO: "PROFILE_INFO",
    TIMEZONE_LOOKUP: "TIMEZONE_LOOKUP",
    CHAT_TOPICS: "CHAT_TOPICS",
    CHAT_ALL_TOPICS: "CHAT_ALL_TOPICS",
    SUGGESTED_TOPICS: "SUGGESTED_TOPICS",
    TOPIC_LOOKUP: "TOPIC_LOOKUP"
};

export const RESOURCE_TYPE = {
    PARENT: 0,
    PDF: 1,
    LINK: 2
};

export const CATEGORY_TYPE = {
    CATEGORY: {
        name: "Category",
        value: 1
    },
    SUB_CATEGORY: {
        name: "Sub Category",
        value: 2
    }
};

export const USER_ROLES = {
    Administrator: { text: "Administrator", value: 1 },
    Moderator: { text: "Moderator", value: 2 }
};

export const ADMIN_ROLE = [
    { text: "Administrator", value: 1 },
    { text: "Moderator", value: 2 }
];

export const APP_NAME = "Baloctech";
export const APP_NAME_MINI = "Baloctech";
export const FOOTER_TEXT_APP_NAME = "Copyright 2022 - Baloctech - All Rights Reserved!®";

export const THEME = {
    LITE: "light",
    DARK: "dark"
};

export const DASHBOARD_TAB_STATUS = [
    {
        title: "Post & Comments"
    },
    {
        title: "Activities"
    },
    {
        title: "Freebies"
    }
];

export const QUIZ_STATUS = {
    Invalid: "Invalid",
    Rejected: "Rejected",
    Incomplete: "Incomplete",
    Approved: "Approved"
};

export const AccountStatus = {
    Disabled: "Disabled",
    Inactive: "Inactive",
    Invited: "Invited",
    Active: "Active"
};

export const SORT_ORDER = {
    ASC: "ascend",
    DESC: "descend"
};

export const SORT_ORDER_VALUE = {
    ascend: "ASC",
    descend: "DESC"
};

export const exportTypes = {
    CSV: "csv"
};

export const IMAGE_VALIDATION_LIMIT = {
    LIMIT: "You have reached the maximum images uploaded!"
};

export const CITY = [
    {
        key: 0,
        label: "Dallas",
        value: 0
    },
    {
        key: 1,
        label: "Karachi",
        value: 1
    },
    {
        key: 2,
        label: "Islamabad",
        value: 2
    }
];
export const SURVIVORS = [
    {
        key: 0,
        label: "Cynthia M Pilkington",
        value: 0
    },
    {
        key: 1,
        label: "Daniel R O'Brien",
        value: 1
    },
    {
        key: 2,
        label: "Adrian Chelariu",
        value: 2
    }
];

export const STATUS_OPTIONS = [
    {
        id: 30,
        name: "Unpublished"
    },
    {
        id: 20,
        name: "Published"
    }
];

export const REQUESTED_STATUS_OPTIONS = [
    {
        id: 50,
        name: "Approved"
    },
    {
        id: 40,
        name: "Declined"
    },
    {
        id: 10,
        name: "Pending for Approval"
    },
    {
        id: 60,
        name: "Pending for Creation"
    }
];

export const TYPE_OPTIONS = [
    {
        id: 2,
        name: "User"
    },
    {
        id: 3,
        name: "System"
    },
    {
        id: 1,
        name: "Admin"
    }
];

export const STATES = [
    {
        key: 0,
        label: "TX",
        value: 0
    },
    {
        key: 1,
        label: "DX",
        value: 1
    },
    {
        key: 2,
        label: "GX",
        value: 2
    }
];

export const IMAGE_SUPPORTED_FORMAT = ["image/jpg", "image/jpeg", "image/png", "images/jfif"];

export const normFile = (e) => {
    if (Array.isArray(e)) {
        return e;
    }

    return e && e.fileList;
};

export const OTHER_OPTION = "Other";
export const CONTENT_VIOLATION_ERROR = "Your content violates our system rules.";
export const DAYS_OF_WEEK = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

export const EVENT_RECURRENCE_TYPE = {
    NONE: 0,
    SIMPLE: 1,
    COMPLEX: 2
};
export const YEAR_FORMAT = "YYYY-MM-DD";
export const DATE_FORMAT = "MM/DD/YYYY";
export const TIME_FORMAT = "H:mm";
export const DATE_TIME_FORMAT = "MM/DD/YYYY, hh:mm a";
export const MAX_FILE_SIZE = 6;

export const DELETE_EVENT_TYPE = {
    SERIES: 1,
    SINGLE: 2
};

export const TRANSPORTATION_TYPE_ID = {
    BUS: 1,
    EMBLEM: 2
};

export const TAB_STATUS = {
    Post: "Post & Comments",
    Activities: "Activities",
    Freebies: "Freebies"
};

export const LOGOUT_KEYS = [STORAGE_CONST.TOKEN, STORAGE_CONST.ADMIN_INFO, STORAGE_CONST.EXTERNAL_TOKEN];

export const TOP_BAR_TITLE = {
    [AUTHENTICATED_ROUTES.DASHBOARD]: "Dashboard",
    [AUTHENTICATED_ROUTES.ACTIVITIES]: "Activities",
    [AUTHENTICATED_ROUTES.REPORTED_ACTIVITIES]: "Reported Activities",
    [AUTHENTICATED_ROUTES.MY_PROFILE]: "My Profile",
    [AUTHENTICATED_ROUTES.FREEBIE_CATEGORY]: "Categories",
    [AUTHENTICATED_ROUTES.REPORTED_FREEBIES]: "Reported Freebies",
    [AUTHENTICATED_ROUTES.ADD_INTERNAL_ACTIVITY]: "Add Internal Activity",
    [AUTHENTICATED_ROUTES.REPORTED_CONTENT]: "Reported Content",
    [AUTHENTICATED_ROUTES.CHAT_MANAGEMENT]: "Topics"
};

export const LANGUAGE_ID = {
    ENGLISH: 1,
    SPANISH: 2
};

export const MONTHS = {
    0: "Jan",
    1: "Feb",
    2: "Mar",
    3: "April",
    4: "May",
    5: "June",
    6: "July",
    7: "Aug",
    8: "Sep",
    9: "Oct",
    10: "Nov",
    11: "Dec"
};
export const DATE_TYPES = {
    Months: "month",
    Days: "day",
    Year: "year",
    Week: "week"
};

export const DETAIL_STATS_HEADINGS = {
    matches: "Matches",
    rounds: "Rounds",
    hukumRounds: "Hukum Rounds",
    sunRounds: "Sun Rounds",
    baloots: "Baloots",
    kaboots: "Kaboots",
    projects: "Projects",
    sira: "Sira",
    hundred: "100",
    fourHundred: "400",
    fifty: "50",
    mostVisitedAppScreen: "Most Visited App Screen",
    avgScreensPerSession: "Avg screens per session",
    avgTimeSpentPerScreen: "Avg time spent per screen",
    websiteVisits: "Click on Websites",
    shareResultClicks: "Share Result Clicks"
};
export const MapCardDetails = {
    DIAMOND: {
        A: { image: require("../assets/cards/AD.png"), title: "Ace" },
        K: { image: require("../assets/cards/KD.png"), title: "King" },
        Q: { image: require("../assets/cards/QD.png"), title: "Queen" },
        J: { image: require("../assets/cards/JD.png"), title: "Jack" },
        10: { image: require("../assets/cards/10D.png"), title: "10th" },
        9: { image: require("../assets/cards/9D.png"), title: "9th" },
        8: { image: require("../assets/cards/8D.png"), title: "8th" },
        7: { image: require("../assets/cards/7D.png"), title: "7th" }
    },
    SPADE: {
        A: { image: require("../assets/cards/AS.png"), title: "Ace" },
        K: { image: require("../assets/cards/KS.png"), title: "King" },
        Q: { image: require("../assets/cards/QS.png"), title: "Queen" },
        J: { image: require("../assets/cards/JS.png"), title: "Jack" },
        10: { image: require("../assets/cards/10S.png"), title: "10th" },
        9: { image: require("../assets/cards/9S.png"), title: "9th" },
        8: { image: require("../assets/cards/8S.png"), title: "8th" },
        7: { image: require("../assets/cards/7S.png"), title: "7th" }
    },
    HEART: {
        A: { image: require("../assets/cards/AH.png"), title: "Ace" },
        K: { image: require("../assets/cards/KH.png"), title: "King" },
        Q: { image: require("../assets/cards/QH.png"), title: "Queen" },
        J: { image: require("../assets/cards/JH.png"), title: "Jack" },
        10: { image: require("../assets/cards/10H.png"), title: "10th" },
        9: { image: require("../assets/cards/9H.png"), title: "9th" },
        8: { image: require("../assets/cards/8H.png"), title: "8th" },
        7: { image: require("../assets/cards/7H.png"), title: "7th" }
    },
    CLUB: {
        A: { image: require("../assets/cards/AC.png"), title: "Ace" },
        K: { image: require("../assets/cards/KC.png"), title: "King" },
        Q: { image: require("../assets/cards/QC.png"), title: "Queen" },
        J: { image: require("../assets/cards/JC.png"), title: "Jack" },
        10: { image: require("../assets/cards/10C.png"), title: "10th" },
        9: { image: require("../assets/cards/9C.png"), title: "9th" },
        8: { image: require("../assets/cards/8C.png"), title: "8th" },
        7: { image: require("../assets/cards/7C.png"), title: "7th" }
    }
};
